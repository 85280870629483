import { flatten, sortBy, uniq } from "lodash";

import { slugifyTag } from "./src/utils/tagSlug";

export type BlogPost = {
  id: string;
  title: string;
  description: string;
  slug: string;
  date: Date;
  tags: Array<string>;
  minutesToRead: number;
  visible: boolean;
  relatedPostIds: Array<string>;
  heroImage: any;
};
export const blogPostsBase: Array<BlogPost> = [
  {
    id: "why-php-goto-and-bubblesort-are-good",
    title: "Why PHP, goto, and bubblesort, are good, actually 👍",
    description:
      "Some technologies, like PHP, goto, and bubblesort, raise concern just by mentioning them. By understanding their history better, we can better improve our own programming skills.",
    slug: "why-php-goto-and-bubblesort-are-good",
    date: new Date("2022-01-08"),
    tags: ["Programming", "Intentful Code", "Algorithms", "PHP"],
    minutesToRead: 15,
    visible: true,
    relatedPostIds: ["make-returns-explicit"],
    heroImage: require("./src/app/(core)/writing/(blogposts)/why-php-goto-and-bubblesort-are-good/bubbles.jpeg"), // https://unsplash.com/photos/ZDUXvlyU_iI
  },
  {
    id: "next-middleware-private-pages",
    title: "Protect Preview Pages 50% Faster with NextJS Middleware 🔒", // smart, protect, verify, ironclad, secure,
    description:
      "By using middleware to serve different routes to public and authenticated users, you can keep public pages zippy, while letting your team preview in a real environment.",
    slug: "next-middleware-private-pages",
    date: new Date("2021-12-05"),
    tags: ["NextJS", "Javascript", "Programming", "React"],
    minutesToRead: 10,
    visible: true,
    relatedPostIds: ["how-concurrent-react-changes-the-game-for-data-heavy-ui"],
    heroImage: require("./src/pages/writing/next-middleware-private-pages/husky.jpeg"), // https://unsplash.com/photos/_ioW232WUAc
  },
  {
    id: "make-returns-explicit",
    title: "Make your returns f**king explicit 🔥",
    description:
      "How can the way we write our code set up future developers for success?",
    slug: "make-returns-explicit",
    date: new Date("2021-10-16"),
    tags: ["Javascript", "Programming", "React", "Intentful Code"],
    minutesToRead: 10,
    visible: true,
    relatedPostIds: [
      "principle-least-astonishment-javascript-sort",
      "halt-in-the-name-of-c-or-why-c-functions-dont-need-a-return-keyword",
    ],
    heroImage: require("./src/pages/writing/make-returns-explicit/fox.jpg"),
  },
  {
    id: "how-concurrent-react-changes-the-game-for-data-heavy-ui",
    title: "How Concurrent React changes the game for data-heavy UI 🕰️",
    description:
      "Concurrent React and Suspense are shaping up to heavily change how dynamic data-driven UIs are built.",
    slug: "how-concurrent-react-changes-the-game-for-data-heavy-ui",
    date: new Date("2019-11-10"),
    tags: ["Frontend Web Development", "React", "Programming"],
    minutesToRead: 7,
    visible: true,
    relatedPostIds: ["counting-crowds-hyperloglog-in-simple-terms"],
    heroImage: require("./src/pages/writing/how-concurrent-react-changes-the-game-for-data-heavy-ui/example.png"),
  },
  {
    id: "getting-into-gamedev",
    title: "So you want to make video games, but you’re still at school",
    description:
      "Game development is easy to get started in — and there’s no need to wait to begin. Learn how to get started today!",
    slug: "getting-into-gamedev",
    date: new Date("2018-09-27"),
    tags: ["Game Development"],
    minutesToRead: 7,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/getting-into-gamedev/seaofthieves.jpg"),
  },
  {
    id: "counting-crowds-hyperloglog-in-simple-terms",
    title: "Counting crowds: HyperLogLog in simple terms",
    description:
      "Learn how to count billions of distinct elements quickly, using very little memory.",
    slug: "counting-crowds-hyperloglog-in-simple-terms",
    date: new Date("2020-04-13"),
    tags: ["Simplified", "Computer Science", "Programming"],
    minutesToRead: 9,
    visible: true,
    relatedPostIds: ["how-concurrent-react-changes-the-game-for-data-heavy-ui"],
    heroImage: require("./src/pages/writing/counting-crowds-hyperloglog-in-simple-terms/3_visitorcount.png"),
  },
  {
    id: "five-tips-giving-great-feedback",
    title: "5 Tips for Giving Great Feedback 🗣️✔️",
    description:
      "Sharing your thoughts with a colleague can be difficult, so knowing how to effectively can improve feedback significantly.",
    slug: "five-tips-giving-great-feedback",
    date: new Date("2018-10-12"),
    tags: ["Teamwork"],
    minutesToRead: 8,
    visible: true,
    relatedPostIds: ["five-tips-receiving-feedback-effectively"],
    heroImage: require("./src/pages/writing/five-tips-giving-great-feedback/1_puffins.jpg"),
  },
  {
    id: "five-tips-receiving-feedback-effectively",
    title: "5 Tips for Receiving Feedback in an Effective Manner 👂✔️",
    description:
      "Receiving feedback is a great opportunity to grow yourself as a person, and doing so mindfully can greatly improve the results.",
    slug: "five-tips-receiving-feedback-effectively",
    date: new Date("2018-10-12"),
    tags: ["Teamwork"],
    minutesToRead: 6,
    visible: true,
    relatedPostIds: ["five-tips-giving-great-feedback"],
    heroImage: require("./src/pages/writing/five-tips-receiving-feedback-effectively/1_dogs.png"),
  },
  {
    id: "floating-points-numbers-simplified",
    title:
      "Floating point numbers for people who don’t know or care what binary is 🚢",
    description:
      "Ignore the scary maths, let’s look at a simplified model for understanding floating point numbers under the hood.",
    slug: "floating-points-numbers-simplified",
    date: new Date("2020-01-06"),
    tags: ["Simplified", "Computer Science", "Programming"],
    minutesToRead: 9,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/floating-points-numbers-simplified/main_sealion.jpg"),
  },
  {
    id: "cpp-moves-simplified",
    title: "C++ moves for people who don’t know or care what rvalues are 🏘️",
    description:
      "Moves in C++ don’t require understanding of deep technical juju to get a grasp on.",
    slug: "cpp-moves-simplified",
    date: new Date("2019-01-17"),
    tags: ["Simplified", "Computer Science", "Programming", "CPlusPlus"],
    minutesToRead: 8,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/cpp-moves-simplified/1_hermitcrab.jpg"),
  },
  {
    id: "keep-functions-short-and-sweet",
    title:
      "Make complex merges be a treat; keep your functions short and sweet! 🍬",
    description:
      "When working in a large code-base, keeping functions short and atomic can help a great deal when managing other people's code.",
    slug: "keep-functions-short-and-sweet",
    date: new Date("2018-05-12"),
    tags: ["Programming", "Teamwork"],
    minutesToRead: 8,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/keep-functions-short-and-sweet/1_merge.png"),
  },
  {
    id: "secrets-of-the-golden-angle",
    title: "Secrets of the Golden Angle, and unique colour generation 🌈",
    description:
      "Using a neat bit of maths, we can generate an infinite list of unique contrasting colours.",
    slug: "secrets-of-the-golden-angle",
    date: new Date("2018-04-27"),
    tags: ["Programming", "Design", "Color", "Mathematics"],
    minutesToRead: 4,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/secrets-of-the-golden-angle/1_lorikeet.png"),
  },
  {
    id: "principle-least-astonishment-javascript-sort",
    title: "The Principle Of Least Astonishment 😲 and JavaScript’s .sort()",
    description:
      "When we write code, we should take a moment to consider, “If someone else was reading this for the first time, what would they expect?",
    slug: "principle-least-astonishment-javascript-sort",
    date: new Date("2018-06-14"),
    tags: ["JavaScript", "Programming", "Teamwork"],
    minutesToRead: 4,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/principle-least-astonishment-javascript-sort/1_child.jpg"),
  },
  {
    id: "javascripts-new-keyword-under-the-hood",
    title: "JavaScript's new keyword, under the hood 🐬",
    description: "Or, how classes are implemented in terms of prototypes",
    slug: "javascripts-new-keyword-under-the-hood",
    date: new Date("2019-09-30"),
    tags: ["JavaScript", "New Keyword", "Programming"],
    minutesToRead: 8,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/javascripts-new-keyword-under-the-hood/1_meerkat.jpg"),
  },
  {
    id: "javas-new-keyword-under-the-hood",
    title: "Java's new keyword, under the hood ☕️",
    description: "Or why sometimes 128 != 128 in Java.",
    slug: "javas-new-keyword-under-the-hood",
    date: new Date("2019-03-27"),
    tags: ["Java", "New Keyword", "Programming"],
    minutesToRead: 7,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/javas-new-keyword-under-the-hood/1_cat.jpg"),
  },
  {
    id: "cplusplus-lambdas-arent-magic-part-1",
    title: "C++ Lambdas aren’t magic, part 1 🧙",
    description:
      "Lambdas are regularly seen as a confusing topic for all levels of developer. In reality, they’re a shorter way to write very specific classes.",
    slug: "cplusplus-lambdas-arent-magic-part-1",
    date: new Date("2018-10-19"),
    tags: ["CPlusPlus", "Programming"],
    minutesToRead: 6,
    visible: true,
    relatedPostIds: ["cplusplus-lambdas-arent-magic-part-2"],
    heroImage: require("./src/pages/writing/cplusplus-lambdas-arent-magic-part-1/1_lamb.jpg"),
  },
  {
    id: "cplusplus-lambdas-arent-magic-part-2",
    title: "C++ Lambdas aren’t magic, part 2 🎆",
    description:
      "There’s a lot of myths and odd-questions around lambdas. This guide will give you a deeper understanding of their rules and how they work.",
    slug: "cplusplus-lambdas-arent-magic-part-2",
    date: new Date("2018-11-13"),
    tags: ["CPlusPlus", "Programming"],
    minutesToRead: 8,
    visible: true,
    relatedPostIds: ["cplusplus-lambdas-arent-magic-part-1"],
    heroImage: require("./src/pages/writing/cplusplus-lambdas-arent-magic-part-2/1_wizards.png"),
  },
  {
    id: "cpp-if-statement-variables",
    title:
      "What If? Declaring Variables in If Statements, and the Curiosities of Scope That Follows 🔭",
    description:
      "tl;dr: Variables declared in if statement conditions are accessible in the else statement as well.",
    slug: "cpp-if-statement-variables",
    date: new Date("2018-08-21"),
    tags: ["CPlusPlus", "Programming"],
    minutesToRead: 5,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/cpp-if-statement-variables/1_dog.jpg"),
  },
  {
    id: "unreal-4-enums-and-accidental-pointer-arithmetic",
    title: "Enums and accidental pointer arithmetic in Unreal 4",
    description:
      "How a combination of unexpected pointer arithmetic and enum implicit conversion made some simple and idiomatic code break.",
    slug: "unreal-4-enums-and-accidental-pointer-arithmetic",
    date: new Date("2018-11-20"),
    tags: ["CPlusPlus", "Unreal 4", "Programming"],
    minutesToRead: 4,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/unreal-4-enums-and-accidental-pointer-arithmetic/1_raccoon.jpeg"),
  },
  {
    id: "halt-in-the-name-of-c-or-why-c-functions-dont-need-a-return-keyword",
    title:
      "Halt! ✋ In the name of C++; or why C++ functions don’t need a return keyword",
    description:
      "C++ functions do not actually require a return keyword in them to be well-formed and compile, though it will likely cause undefined behaviour.",
    slug: "halt-in-the-name-of-c-or-why-c-functions-dont-need-a-return-keyword",
    date: new Date("2018-09-07"),
    tags: ["CPlusPlus", "Programming"],
    minutesToRead: 5,
    visible: true,
    relatedPostIds: [],
    heroImage: require("./src/pages/writing/halt-in-the-name-of-c-or-why-c-functions-dont-need-a-return-keyword/1_dog.jpeg"),
  },
];
export const blogPosts: Array<BlogPost> = sortBy(
  blogPostsBase.filter((post) => post.visible),
  (post) => new Date(post.date)
);

export const allTagsRich = uniq(
  flatten(blogPosts.map((post) => post.tags))
).map((tag) => {
  return { baseTag: tag, slugTag: slugifyTag(tag) };
});
