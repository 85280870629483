"use client";

import EntypoTwitter from "react-entypo/lib/entypo/Twitter";
import EntypoLinkedin from "react-entypo/lib/entypo/Linkedin";
import EntypoMail from "react-entypo/lib/entypo/Mail";
import EntypoHome from "react-entypo/lib/entypo/Home";

export function SocialLinks() {
  return (
    <ul className="grid w-32 grid-cols-4 gap-4 md:mr-1 md:mt-0">
      <li>
        <a
          href="https://twitter.com/winwardo"
          target="_blank"
          rel="noopener"
          className="relative z-10"
        >
          <EntypoTwitter
            aria-hidden
            color={"hsl(210, 75%, 56%)"}
            style={{ height: "1.5rem", width: "1.5rem", marginTop: "2.5px" }}
          />
          <span className="sr-only">Twitter: @winwardo</span>
        </a>
      </li>
      <li>
        <a
          href="https://topher.io"
          target="_blank"
          rel="noopener"
          className="relative z-10"
        >
          <EntypoHome
            aria-hidden
            color={"hsl(280,75%,56%)"}
            style={{ height: "1.5rem", width: "1.5rem", marginTop: "2px" }}
          />
          <span className="sr-only">Topher's website: https://topher.io</span>
        </a>
      </li>
      <li>
        <a
          href="http://uk.linkedin.com/in/topherwinward"
          target="_blank"
          rel="noopener"
          className="relative z-10"
        >
          <EntypoLinkedin
            aria-hidden
            color={"hsl(10,75%,56%)"}
            style={{ height: "1.5rem", width: "1.5rem", marginTop: "0.5px" }}
          />
          <span className="sr-only">LinkedIn</span>
        </a>
      </li>
      <li>
        <a
          href="mailto:hello@topherwinward.com"
          target="_blank"
          className="relative z-10"
        >
          <EntypoMail
            aria-hidden
            color={"hsl(110,75%,56%)"}
            style={{ height: "1.5rem", width: "1.5rem", marginTop: "4.0px" }}
          />

          <span className="sr-only">Email: hello@topherwinward.com</span>
        </a>
      </li>
    </ul>
  );
}
